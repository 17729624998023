import Chart from 'chart.js/auto';

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['chart'];
  canvasContext() { return this.chartTarget.getContext('2d'); }
  datasetLabels() { return JSON.parse(this.data.get('labels')) }
  datasetValues() { return JSON.parse(this.data.get('values')) }

  connect() {
    new Chart(this.canvasContext(), {
      type: 'pie',
      data: {
        labels: this.datasetLabels(),
        datasets: [{
          data: this.datasetValues()
        }]
      },
      options: {
        plugins: {
          legend: {
            align: 'start'
          }
        }
      }
    })
  }
}